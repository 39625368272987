<template>
  <div>
    <CRow>
      <CCol sm="4">
        <h4 class="text-uppercase">Customer Accounts</h4>
      </CCol>

      <CCol sm="8"> </CCol>
    </CRow>

    <CRow>
      <CCol sm="12">
        <CCard>
          <CRow class="pb-3">
            <CCol sm="10">
              <form v-on:submit.prevent="filterCustomer(filter)">
                <CRow>
                  <CCol sm="2" class="pr-0">
                    <CInput v-model="filter.name" placeholder="Customer Name" />
                  </CCol>
                  <CCol sm="2" class="pr-0">
                    <CInput
                      v-model="filter.mobile_number"
                      placeholder="Mobile No"
                    />
                  </CCol>
                  <CCol sm="2" class="pr-0">
                    <CInput
                      v-model="filter.account_number"
                      placeholder="Account Number"
                    />
                  </CCol>
                  <CCol sm="2" class="pr-0">
                    <CInput
                      v-model="filter.ward_number"
                      placeholder="Ward No"
                    />
                  </CCol>
                  <CCol sm="2">
                    <button class="btn btn-success w-100">Search</button>
                  </CCol>
                </CRow>
              </form>
            </CCol>

            <CCol sm="2">
              <button
                v-if="checkUsrPermissions(['CAcc_all', 'CAcc_create'])"
                @click="createCustomerModal = true"
                class="btn btn-primary btn-sm float-right"
              >
                + Create Customer
              </button>
            </CCol>
          </CRow>
          <CRow>
            <CCol sm="12">
              <div class="table-responsive">
                <table class="table table-borderless">
                  <thead>
                    <tr class="text-center">
                      <th scope="col" class="text-left">Account No</th>
                      <th scope="col" class="text-left">Name</th>
                      <th scope="col">Email</th>
                      <th scope="col">Mobile No</th>
                      <th scope="col">
                        Ward <br />
                        Number
                      </th>
                      <th scope="col">Category</th>
                      <th scope="col">
                        Meter <br />
                        Assigned?
                      </th>
                      <th
                        scope="col"
                        v-if="
                          checkUsrPermissions([
                            'CAcc_all',
                            'CAcc_show',
                            'CAcc_edit',
                            'CAccAssign_team',
                          ])
                        "
                      >
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody v-if="customerAccounts.length">
                    <tr
                      class="text-center"
                      v-for="customer in customerAccounts"
                      :key="customer.id"
                    >
                      <td scope="row" class="text-left">
                        <router-link :to="`/customer-accounts/${customer.id}`">{{ customer.account_number }}</router-link>
                      </td>
                      <td scope="row" class="text-left">
                        {{
                          customer.salutation +
                            " " +
                            customer.first_name +
                            " " +
                            customer.last_name
                        }}
                      </td>
                      <td scope="row">{{ customer.email }}</td>
                      <td scope="row">{{ customer.mobile_number }}</td>
                      <td scope="row">{{ customer.ward_number }}</td>
                      <td scope="row">{{ customer.category }}</td>
                      <td scope="row">
                        {{ customer.hasMeter ? "Yes" : "No" }}
                      </td>
                      <td
                        style="padding: 0px 10px;"
                        scope="row"
                        v-if="
                          checkUsrPermissions([
                            'CAcc_all',
                            'CAcc_show',
                            'CAcc_edit',
                            'CAccAssign_team',
                          ])
                        "
                      >
                        <span
                          v-if="checkUsrPermissions(['CAcc_all', 'CAcc_show'])"
                          class="mb-1"
                          @click="
                            $router.push('/customer-accounts/' + customer.id)
                          "
                          shape="pill"
                        >
                          <i class="fa fa-eye fa-2x color-view"></i>
                        </span>
                        &nbsp; &nbsp; &nbsp;
                        <span
                          class="mb-1"
                          color="secondary"
                          shape="pill"
                          @click="editCustomerAccount(customer)"
                          v-if="checkUsrPermissions(['CAcc_all', 'CAcc_edit'])"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            fill="#5e76e7"
                            width="32"
                            height="32"
                            version="1.1"
                            id="lni_lni-pencil-alt"
                            x="0px"
                            y="0px"
                            viewBox="0 0 64 100"
                            style="enable-background: new 0 0 64 64"
                            xml:space="preserve"
                          >
                            <path
                              d="M62.7,11.2c0-0.7-0.3-1.3-0.8-1.8c-1.3-1.3-2.5-2.5-3.7-3.7c-1.1-1.1-2.2-2.2-3.3-3.4c-0.4-0.5-1-0.9-1.6-1  c-0.7-0.1-1.5,0.1-2.1,0.6l-7.2,7.2H8.7c-4.1,0-7.4,3.3-7.4,7.4v38.9c0,4.1,3.3,7.4,7.4,7.4h38.9c4.1,0,7.4-3.3,7.4-7.4V19.9  l6.9-6.9C62.4,12.5,62.7,11.8,62.7,11.2z M33.3,36.6c-0.1,0.1-0.3,0.2-0.4,0.3l-8.6,2.9l2.8-8.6c0.1-0.2,0.1-0.3,0.3-0.4l19-19  l6,5.9L33.3,36.6z M51.5,55.4c0,2.1-1.7,3.9-3.9,3.9H8.7c-2.1,0-3.9-1.7-3.9-3.9V16.4c0-2.1,1.7-3.9,3.9-3.9h31.9L24.9,28.2  c-0.5,0.5-0.9,1.1-1.1,1.8l-3.8,11.6c-0.2,0.6-0.1,1.2,0.2,1.7c0.3,0.4,0.7,0.8,1.6,0.8h0.3l11.9-3.9c0.7-0.2,1.3-0.6,1.8-1.1  l15.8-15.7V55.4z M54.8,15.1l-6-5.9l4-4c1,1,1.9,1.9,2.9,2.9c1,1,2,2,3,3.1L54.8,15.1z"
                            />
                          </svg>
                        </span>
                        &nbsp; &nbsp; &nbsp;
                        <!-- <CButton
                          color="primary"
                          shape="pill"
                          v-if="!customer.hasMeter"
                          @click="assignMeter(customer.id)"
                          >Add Meter</CButton
                        > -->

                        <CButton
                          v-if="
                            !customer.hasMeter &&
                              customer.hasTask == false &&
                              checkUsrPermissions([
                                'CAcc_all',
                                'CAccAssign_team',
                              ])
                          "
                          color="primary"
                          shape="pill"
                          @click="assignTeam(customer.id)"
                          >Assign Team</CButton
                        >
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr>
                      <td colspan="9">
                        <h5 class="text-center">Data Not Available.</h5>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </CCol>
          </CRow>
          <CPagination
            v-if="customerAccounts.length"
            align="center"
            :pages="rows"
            :active-page.sync="filter.currentPage"
            @update:activePage="handlePageChange"
          />
        </CCard>
      </CCol>
    </CRow>

    <CModal
      :closeOnBackdrop="false"
      size="lg"
      title="Add Customer Account"
      color="primary"
      class="hide-footer custom-close-button"
      :show.sync="createCustomerModal"
    >
      <AddCustomerAcc
        v-if="createCustomerModal"
        @closeAddCustomerModal="closeAddCustomerModal"
      ></AddCustomerAcc>
    </CModal>

    <CModal
      :closeOnBackdrop="false"
      size="lg"
      v-if="editCustomerModal"
      title="Edit Customer Account"
      color="primary"
      class="hide-footer custom-close-button"
      :show.sync="editCustomerModal"
    >
      <EditCustomerAcc
        v-if="editCustomerModal"
        :edit_customer_account="customer"
        @closeEditCustomerModal="closeEditCustomerModal"
      ></EditCustomerAcc>
    </CModal>

    <CModal
      :closeOnBackdrop="false"
      v-if="assignTeamModal"
      title="Assign Team"
      color="primary"
      class="hide-footer custom-close-button"
      :show.sync="assignTeamModal"
    >
      <AssignTeam
        v-if="assignTeamModal"
        :customerId="customerId"
        @closeAssignTeamModal="closeAssignTeamModal"
      ></AssignTeam>
    </CModal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import AddCustomerAcc from "./CreateCustomerAcc";
// import AssignMeter from "./AssignMeter";
import EditCustomerAcc from "./EditCustomerAcc";
import AssignTeam from "./AssignTeam";
export default {
  name: "CustomerAccComp",
  components: {
    AddCustomerAcc,
    // AssignMeter,
    EditCustomerAcc,
    AssignTeam,
  },
  data() {
    return {
      createCustomerModal: false,
      editCustomerModal: false,
      assignTeamModal: false,
      customerId: "",
      customer: {},
      filter: {
        mobile_number: "",
        name: "",
        account_number: "",
        ward_number: "",
        currentPage: 1,
      },
    };
  },
  methods: {
    assignMeter(customerId) {
      this.customerId = customerId;
      this.$router.push({
        name: "AssignMeterToCustomerACC",
        params: { id: customerId },
      });
    },
    closeAddCustomerModal() {
      this.createCustomerModal = false;
    },
    closeEditCustomerModal() {
      this.editCustomerModal = false;
    },
    closeAssignTeamModal() {
      this.assignTeamModal = false;
    },
    handlePageChange(value) {
      this.filter.currentPage = value;
      this.$router.replace({name: "CustomerAccounts", query: this.filter});
      this.$store.dispatch("CustomerAccount/getCustomerAccounts", this.filter);
    },
    filterCustomer(search) {
      if (search) {
        search.currentPage = 1;
        this.$router.replace({name: "CustomerAccounts", query: search});
        this.$store.dispatch("CustomerAccount/getCustomerAccounts", search);
      }
    },
    editCustomerAccount(customer) {
      this.customer = customer;
      this.editCustomerModal = true;
    },
    assignTeam(customerId) {
      this.customerId = customerId;
      this.assignTeamModal = true;
    },
    setSearchParams() {
      this.filter.account_number = this.$route.query.account_number?this.$route.query.account_number:'';
      this.filter.mobile_number = this.$route.query.mobile_number?this.$route.query.mobile_number:'';
      this.filter.name = this.$route.query.name?this.$route.query.name:'';
      this.filter.ward_number = this.$route.query.ward_number?this.$route.query.ward_number:'';
      this.filter.currentPage = this.$route.query.currentPage?parseInt(this.$route.query.currentPage):1;
    }
  },
  computed: {
    ...mapGetters("CustomerAccount", ["customerAccounts", "rows", "perPage"]),
  },
  mounted() {
    this.setSearchParams();
    this.$store.dispatch("CustomerAccount/getCustomerAccounts", this.filter);
  },
};
</script>

<style scoped>
.card {
    border: none;
    padding: 15px 15px 0;
}
tbody:before {
  content: "@";
  display: block;
  /* line-height: 30px; */
  text-indent: -99999px;
}
.hide-footer >>> footer {
  display: none;
}
.custom-close-button >>> .close {
  color: aliceblue;
}
</style>

<style lang="scss" scoped>
div {
  h4 {
    font-size: 15px;
    font-weight: bold !important;
    color: #000;
  }
  .table {
    font-size: 14px;

    thead {
      tr {
        th {
          background: #f8f9fa;
          color: #000;
        }
      }
    }
    tbody {
      font-size: 13px;
      tr {
        color: #000;
        td {
          padding: 0.75rem 0 0.75rem 0.75rem;
          button {
            font-size: 13px;
          }
          span {
            cursor: pointer;
          }
          span .color-view {
            color: #5e76e7;
            padding: 10px;
          }
        }
        td:nth-child(1) {
          color: #5e76e7;
        }
        td:nth-child(2) {
          font-weight: 500 !important;
        }
      }
    }
  }
}
</style>
