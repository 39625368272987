<template>
  <CRow>
    <CCol>
      <div v-if="serverErrors.length">
        <CAlert v-model="showDismissibleAlert" color="warning" closeButton>
          <span v-for="err in serverErrors" :key="err">{{ err }}</span>
        </CAlert>
      </div>

      <validation-observer ref="formValidator">
        <form v-on:submit.prevent="editCustomerAccount">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label>Category</label>
                <select
                  name="category"
                  v-model="customer_account.category"
                  class="form-control"
                  required
                >
                  <option value="">Select</option>
                  <option value="Domestic">Domestic</option>
                  <option value="Industrial">Industrial</option>
                </select>
              </div>
            </div>

            <div class="col-md-6">
              <div class="form-group">
                <label>Connection Type</label>
                <select
                  name="connection_type"
                  v-model="customer_account.connection_type"
                  class="form-control"
                  required
                >
                  <option value="">Select</option>
                  <option value="0.75 INCH">0.75 INCH</option>
                  <option value="1.00 INCH">1.00 INCH</option>
                </select>
              </div>
            </div>

            <div class="col-md-2 col-12">
              <div class="form-group">
                <label>Salutation</label>
                <select
                  name="salutation"
                  v-model="customer_account.salutation"
                  class="form-control"
                  required
                >
                  <option value="">Select</option>
                  <option value="Mr.">Mr</option>
                  <option value="Mrs.">Mrs</option>
                  <option value="Dr.">Dr</option>
                  <option value="Engr.">Engineer</option>
                </select>
              </div>
            </div>

            <div class="col-md-5 col-12">
              <ValidationProvider
                vid="first_name"
                name="First Name"
                :rules="{ required: true, min: 3, alpha_spaces: true }"
                v-slot="{ errors }"
              >
                <div class="form-group">
                  <label>First Name</label>
                  <CInput
                    type="text"
                    v-model="customer_account.first_name"
                    placeholder="Enter customer first name"
                    required
                    :addInputClasses="errors.length ? 'is-invalid' : ''"
                  />
                </div>
                <p class="text-danger mt-0 pt-0">{{ errors[0] }}</p>
              </ValidationProvider>
            </div>
            <div class="col-md-5 col-12">
              <ValidationProvider
                name="Last Name"
                vid="last_name"
                :rules="{ required: true, min: 3, alpha_spaces: true }"
                v-slot="{ errors }"
              >
                <div class="form-group">
                  <label>Last Name</label>
                  <CInput
                    type="text"
                    v-model="customer_account.last_name"
                    placeholder="Enter customer last name"
                    :addInputClasses="errors.length ? 'is-invalid' : ''"
                    required
                  />
                </div>
                <p class="text-danger mt-0 pt-0">
                  {{ errors[0] }}
                </p>
              </ValidationProvider>
            </div>

            <div class="col-md-6">
              <ValidationProvider
                name="Email"
                :rules="{ required: true, email: true }"
                v-slot="{ errors }"
                vid="email"
              >
                <div class="form-group">
                  <label>Email</label>
                  <CInput
                    :addInputClasses="errors.length ? 'is-invalid' : ''"
                    type="email"
                    v-model="customer_account.email"
                    placeholder="Email address"
                    required
                  />
                </div>
                <p class="text-danger mt-0 pt-0">
                  {{ errors[0] }}
                </p>
              </ValidationProvider>
            </div>

            <div class="col-md-6">
              <ValidationProvider
                name="Mobile Number"
                vid="mobile_number"
                :rules="{ required: true, numeric: true, min: 11, digits: 11 }"
                v-slot="{ errors }"
              >
                <div class="form-group">
                  <label>Mobile Number</label>
                  <CInput
                    type="text"
                    :addInputClasses="errors.length ? 'is-invalid' : ''"
                    v-model="customer_account.mobile_number"
                    placeholder="Mobile number"
                    required
                  />
                </div>
                <p class="text-danger mt-0 pt-0">
                  {{ errors[0] }}
                </p>
              </ValidationProvider>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>Country</label>
                <select
                  name="country"
                  v-model="customer_account.country"
                  class="form-control"
                  required
                  @change="getDivisions()"
                >
                  <option value="">Select Country</option>
                  <option
                    v-for="(country, key) in locations"
                    :key="key"
                    :value="country.name"
                  >
                    {{ country.name }}
                  </option>
                </select>
              </div>
            </div>

            <div class="col-md-6">
              <div class="form-group">
                <label>State</label>
                <select
                  name="state"
                  v-model="customer_account.division"
                  class="form-control"
                  required
                  @change="getCities()"
                >
                  <option value="">Select State</option>
                  <option
                    v-for="(division, key) in divisions"
                    :key="key"
                    :value="division.name"
                  >
                    {{ division.name }}
                  </option>
                </select>
              </div>
            </div>

            <div class="col-md-6">
              <div class="form-group">
                <label>City</label>
                <select
                  name="state"
                  v-model="customer_account.city"
                  class="form-control"
                  @change="getAreas()"
                  required
                >
                  <option value="">Select City</option>
                  <option
                    v-for="(item, key) in cities"
                    :key="key"
                    :value="item.name"
                  >
                    {{ item.name }}
                  </option>
                </select>
              </div>
            </div>

            <div class="col-md-6">
              <div class="form-group">
                <label>Area</label>
                <select
                  name="area"
                  v-model="customer_account.area"
                  class="form-control"
                  required
                >
                  <option value="">Select Area</option>
                  <option v-for="(item, key) in areas" :key="key" :value="item">
                    {{ item }}
                  </option>
                </select>
              </div>
            </div>

            <!-- <div class="col-md-6">
              <div class="form-group">
                <label>Area</label>
                <select
                  name="area"
                  v-model="customer_account.area"
                  class="form-control"
                  required
                >
                  <option value="">Select</option>
                  <option value="Agrabad">Agrabad</option>
                  <option value="Dampara">Dampara</option>
                  <option value="Halishahar">Halishahar</option>
                  <option value="Kalurghat">Kalurghat</option>
                  <option value="Jubilee Road">Jubilee Road</option>
                </select>
              </div>
            </div> -->
            <div class="col-md-6">
              <div class="form-group">
                <label>Ward Number</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="customer_account.ward_number"
                  placeholder="Ward Number"
                  required
                />
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group">
                <label>Address</label>
                <textarea
                  name="address"
                  class="form-control"
                  v-model="customer_account.address"
                ></textarea>
              </div>
            </div>
          </div>

          <button type="submit" class="btn btn-primary">Submit</button>
        </form>
      </validation-observer>
    </CCol>
  </CRow>
</template>

<script>
import locations from "../../assets/json/locations.json";
export default {
  name: "EditCustomerAcc",
  props: ["edit_customer_account"],
  data: () => {
    return {
      locations: locations,
      customer_account: {},
      serverErrors: [],
      showDismissibleAlert: false,
      isLoading: false,
      fieldErrors: {},
      divisions: [],
      cities: [],
      areas: [],
    };
  },
  methods: {
    getDivisions(resetData) {
      resetData = resetData ? resetData : false;
      if (resetData) {
        this.customer_account.division = "";
        this.customer_account.city = "";
        this.customer_account.area = "";
        this.cities = [];
        this.areas = [];
      }
      let country = this.locations.find((item) => {
        if (item.name === this.customer_account.country) {
          return item.states;
        }
      });
      this.divisions = country.states;
    },
    getCities(resetData) {
      resetData = resetData ? resetData : false;
      if (resetData) {
        this.customer_account.city = "";
        this.customer_account.area = "";
        this.areas = [];
      }
      let division = this.divisions.find((item) => {
        if (item.name === this.customer_account.division) {
          return item;
        }
      });
      this.cities = division.districts;
    },
    getAreas(resetData) {
      resetData = resetData ? resetData : false;
      if (resetData) {
        this.customer_account.area = "";
      }
      let city = this.cities.find((item) => {
        if (item.name === this.customer_account.city) {
          return item;
        }
      });
      this.areas = city.areas;
    },
    closeModal() {
      this.$emit("closeEditCustomerModal");
    },
    editCustomerAccount() {
      try {
        this.$store.dispatch("Settings/loading", true);
        this.$store
          .dispatch("CustomerAccount/update", this.customer_account)
          .then(
            () => {
              this.$store.dispatch("Settings/loading", false);
              this.$toastr.s(
                "Successfuly Updated customer account",
                "Account Updated"
              );
              this.closeModal();
            },
            (error) => {
              this.serverErrors = [];
              if (error.response.data) {
                this.serverErrors.push(error.response.data.message);
                this.$refs.formValidator.setErrors(error.response.data.errors);
              }
              this.$toastr.e(error.response.data.message, "Failed!");
              this.$store.dispatch("Settings/loading", false);
            }
          );
      } catch (err) {
        console.log(err);
        this.isLoading = false;
      }
    },
    resetCityArea() {
      this.customer_account.city = "";
      this.customer_account.area = "";
    },
  },
  mounted() {
    this.customer_account = { ...this.edit_customer_account };
    this.customer_account.division = this.customer_account.state;
    if (this.customer_account.id) {
      this.getDivisions(false);
      this.getCities(false);
      this.getAreas(false);
    }
  },
};
</script>

<style lang="scss" scoped>
.actions {
  button {
    margin: 10px;
    padding: 15px 20px;
  }
}
</style>
