<template>
  <CRow>
    <CCol>
      <div v-if="serverErrors.length">
        <CAlert v-model="showDismissibleAlert" color="warning" closeButton>
          <span v-for="err in serverErrors" :key="err">{{ err }}</span>
        </CAlert>
      </div>

      <div class="row" v-if="!accountType">
        <div class="col-md-12 text-center actions">
          <button
            type="button"
            class="btn btn-outline-primary btn-lg"
            @click="selectAccountType('new')"
          >
            New Customer
          </button>
          <button
            type="button"
            class="btn btn-outline-primary btn-lg"
            @click="selectAccountType('old')"
          >
            Existing Customer
          </button>
        </div>
      </div>

      <div class="row" v-if="accountType === 'old'">
        <div class="col-md-9 text-center actions">
          <input
            type="search"
            class="form-control"
            v-model="search.account_number"
            placeholder="Search by account number"
            @keyup.enter="searchCustomer()"
            required
          />
        </div>
        <div class="col-md-3">
          <button
            type="button"
            class="btn btn-primary"
            @click="searchCustomer()"
          >
            Search
          </button>
        </div>
        <div class="col-md-12" v-if="customer_account.customer_id">
          <hr />
        </div>
      </div>

      <validation-observer ref="formValidator">
        <form
          v-on:submit.prevent="addCustomerAccount"
          v-if="
            accountType === 'new' ||
              (accountType === 'old' && customer_account.customer_id)
          "
        >
          <div class="row">
            <div class="col-md-5">
              <div class="form-group">
                <label>System Generated Account No?</label>
                <div class="row">
                  <div class="checkbox col-md-4 pl-4">
                    <label>
                      <input
                        type="radio"
                        name="generate_ac_no"
                        v-model="customer_account.generate_account_no"
                        :value="true"
                      />
                      Yes
                    </label>
                  </div>

                  <div class="checkbox col-md-4">
                    <label>
                      <input
                        type="radio"
                        name="generate_ac_no"
                        v-model="customer_account.generate_account_no"
                        :value="false"
                      />
                      No
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-7" v-if="!customer_account.generate_account_no">
              <ValidationProvider
                name="Enter Customer's Account No."
                vid="account_number"
                :rules="{ required: true }"
                v-slot="{ errors }"
              >
                <div class="form-group">
                  <label>Customer's Account No.</label>
                  <CInput
                    :addInputClasses="errors.length ? 'is-invalid' : ''"
                    type="text"
                    v-model="customer_account.account_number"
                    placeholder="Enter Customer's Account No."
                    required
                  />
                </div>
                <span class="text-danger mt-0 pt-0">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label>Category</label>
                <select
                  name="category"
                  v-model="customer_account.category"
                  class="form-control"
                  required
                >
                  <option value="">Select</option>
                  <option value="Domestic">Domestic</option>
                  <option value="Industrial">Industrial</option>
                </select>
              </div>
            </div>

            <div class="col-md-6">
              <div class="form-group">
                <label>Connection Type</label>
                <select
                  name="connection_type"
                  v-model="customer_account.connection_type"
                  class="form-control"
                  required
                >
                  <option value="">Select</option>
                  <option value="0.75 INCH">0.75 INCH</option>
                  <option value="1.00 INCH">1.00 INCH</option>
                </select>
              </div>
            </div>

            <div class="col-md-2 col-12">
              <div class="form-group">
                <label>Salutation</label>
                <select
                  name="salutation"
                  v-model="customer_account.salutation"
                  class="form-control"
                  required
                >
                  <option value="">Select</option>
                  <option value="Mr.">Mr</option>
                  <option value="Mrs.">Mrs</option>
                  <option value="Dr.">Dr</option>
                  <option value="Engr.">Engineer</option>
                </select>
              </div>
            </div>

            <div class="col-md-5 col-12">
              <ValidationProvider
                vid="first_name"
                name="First Name"
                :rules="{ required: true, alpha_spaces: true, min: 3 }"
                v-slot="{ errors }"
              >
                <div class="form-group">
                  <label>First Name</label>
                  <CInput
                    type="text"
                    v-model="customer_account.first_name"
                    placeholder="Enter customer first name"
                    required
                    :addInputClasses="errors.length ? 'is-invalid' : ''"
                  />
                </div>
                <span class="text-danger mt-0 pt-0">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
            <div class="col-md-5 col-12">
              <ValidationProvider
                vid="last_name"
                name="Last Name"
                :rules="{ required: true, alpha_spaces: true, min: 3 }"
                v-slot="{ errors }"
              >
                <div class="form-group">
                  <label>Last Name</label>
                  <CInput
                    type="text"
                    v-model="customer_account.last_name"
                    placeholder="Enter customer last name"
                    :addInputClasses="errors.length ? 'is-invalid' : ''"
                    required
                  />
                </div>
                <span class="text-danger mt-0 pt-0">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>

            <div class="col-md-6">
              <ValidationProvider
                name="Email"
                vid="email"
                :rules="{ required: true, email: true }"
                v-slot="{ errors }"
              >
                <div class="form-group">
                  <label>Email</label>
                  <CInput
                    :addInputClasses="errors.length ? 'is-invalid' : ''"
                    type="email"
                    v-model="customer_account.email"
                    placeholder="Email address"
                    required
                  />
                </div>
                <span class="text-danger mt-0 pt-0">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>

            <div class="col-md-6">
              <ValidationProvider
                vid="mobile_number"
                name="Mobile Number"
                :rules="{ required: true, numeric: true, min: 11, digits: 11 }"
                v-slot="{ errors }"
              >
                <div class="form-group">
                  <label>Mobile Number</label>
                  <CInput
                    type="text"
                    :addInputClasses="errors.length ? 'is-invalid' : ''"
                    v-model="customer_account.mobile_number"
                    placeholder="Mobile number"
                    required
                  />
                </div>
                <span class="text-danger mt-0 pt-0">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>

            <div class="col-md-6">
              <div class="form-group">
                <label>Country</label>
                <select
                  name="country"
                  v-model="customer_account.country"
                  class="form-control"
                  required
                  @change="getDivisions()"
                >
                  <option value="">Select Country</option>
                  <option
                    v-for="(country, key) in locations"
                    :key="key"
                    :value="country.name"
                    >{{ country.name }}</option
                  >
                </select>
              </div>
            </div>

            <div class="col-md-6">
              <div class="form-group">
                <label>State</label>
                <select
                  name="state"
                  v-model="customer_account.division"
                  class="form-control"
                  required
                  @change="getCities()"
                >
                  <option value="">Select State</option>
                  <option
                    v-for="(division, key) in divisions"
                    :key="key"
                    :value="division.name"
                    >{{ division.name }}</option
                  >
                </select>
              </div>
            </div>

            <div class="col-md-6">
              <div class="form-group">
                <label>City</label>
                <select
                  name="state"
                  v-model="customer_account.city"
                  class="form-control"
                  @change="getAreas()"
                  required
                >
                  <option value="">Select City</option>
                   <option
                    v-for="(item, key) in cities"
                    :key="key"
                    :value="item.name"
                    >{{ item.name }}</option
                  >
                </select>
              </div>
            </div>

            <div class="col-md-6">
              <div class="form-group">
                <label>Area</label>
                <select
                  name="area"
                  v-model="customer_account.area"
                  class="form-control"
                  required
                >
                  <option value="">Select Area</option>
                  <option
                    v-for="(item, key) in areas"
                    :key="key"
                    :value="item"
                    >{{ item }}</option
                  >
                </select>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>Ward Number</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="customer_account.ward_number"
                  placeholder="Ward Number"
                  required
                />
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group">
                <label>Address</label>
                <textarea
                  name="address"
                  class="form-control"
                  v-model="customer_account.address"
                ></textarea>
              </div>
            </div>
          </div>

          <button v-if="accountType" type="submit" class="btn btn-primary">
            Submit
          </button>
        </form>
      </validation-observer>
    </CCol>
  </CRow>
</template>

<script>
import locations from '../../assets/json/locations.json'
export default {
  name: "CreateCustomerAcc",
  data: () => {
    return {
      locations: locations,
      accountType: "",
      search: {
        account_number: "",
      },
      customer_account: {
        generate_account_no: true,
        customer_id: "",
        category: "Domestic",
        connection_type: "0.75 INCH",
        first_name: "",
        last_name: "",
        email: "",
        salutation: "",
        mobile_number: "",
        address: "",
        area: "",
        city: "",
        division: "",
        country: ""
      },
      serverErrors: [],
      showDismissibleAlert: false,
      isLoading: false,
      divisions: [],
      cities: [],
      areas: [],
    };
  },
  methods: {
    getDivisions() {
      this.customer_account.division = "";
      this.customer_account.city = "";
      this.customer_account.area = "";
      this.cities = [];
      this.areas = [];
      let country = this.locations.find((item) => {
        if(item.name === this.customer_account.country) {
          return item.states;
        }
    });
    this.divisions = country.states;
    },
    getCities() {
      this.customer_account.city = "";
      this.customer_account.area = "";
      this.areas = [];
      let division = this.divisions.find((item) => {
        if(item.name === this.customer_account.division) {
          return item;
        }
    });
    this.cities = division.districts;
    },
    getAreas() {
      this.customer_account.area = "";
      let city = this.cities.find((item) => {
        if(item.name === this.customer_account.city) {
          return item;
        }
    });
    this.areas = city.areas;
    },
    selectAccountType(type) {
      this.accountType = type;
    },
    searchCustomer() {
      this.$store.dispatch("Settings/loading", true);
      return this.axios
        .post(
          `${process.env.VUE_APP_API_BASE_URL}/admin/customer-accounts/search-by-account-no`,
          this.search
        )
        .then(
          (res) => {
            this.setCustomerData(res.data.data);
            this.$store.dispatch("Settings/loading", false);
          },
          (error) => {
            this.$toastr.e("Account number not found.", "Failed!");
            this.$store.dispatch("Settings/loading", false);
            this.resetCustomerData();
          }
        );
    },
    resetCustomerData() {
      this.customer_account = {
        customer_id: "",
        category: "Domestic",
        connection_type: "0.75 INCH",
        first_name: "",
        last_name: "",
        email: "",
        salutation: "",
        mobile_number: "",
        address: "",
        area: "",
        city: "Chattagram",
        division: "Chattagram",
      };
    },
    setCustomerData(data) {
      let fields = [
        "salutation",
        "first_name",
        "last_name",
        "state",
        "city",
        "state",
        "area",
        "email",
        "mobile_number",
        "customer_id",
        "category"
      ];
      for (let key in fields) {
        if (fields[key] === "state") {
          this.customer_account.division = data[fields[key]];
        } else {
          this.customer_account[fields[key]] = data[fields[key]];
        }
      }
    },
    closeModal() {
      this.$emit("closeAddCustomerModal");
    },
    resetCityArea() {
      this.customer_account.city = "";
      this.customer_account.area = "";
    },
    addCustomerAccount() {
      try {
        this.$store.dispatch("Settings/loading", true);
        this.$store
            .dispatch("CustomerAccount/store", this.customer_account)
            .then(() => {
              this.$store.dispatch("Settings/loading", false);
              this.$toastr.s(
                  "Successfully Added customer account",
                  "Account Created"
              );
              this.closeModal();
              this.resetCustomerData();
            })
            .catch((err) => {
              this.$store.dispatch("Settings/loading", false);
              this.serverErrors = [];
              if (err.response.data.message) {
                this.serverErrors.push(err.response.data.message);
              }

              let errors = err.response.data.errors;
              for (let field of Object.keys(errors)) {
                this.$toastr.e("error", errors[field][0], "Error!");
              }
            });
      } catch (err) {
        console.log(err);
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.actions {
  button {
    margin: 10px;
    padding: 15px 20px;
  }
}
</style>
